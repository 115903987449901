import React, { useState } from 'react';
import classes from './DueDate.module.css';
import { BiRefresh, BiBox, BiCheck, BiX } from 'react-icons/bi';
import { IconContext } from 'react-icons';


const DueDateComponent = () => {
    const [isRescheduleClicked, setRescheduleClicked] = useState(false);
    const [dayValue, setDayValue] = useState('');
    const [timeValue, setTimeValue] = useState('');
    const [isComponentVisible, setComponentVisible] = useState(true);



    const handleRescheduleClick = () => {
        setRescheduleClicked(true);
    };

    const handleSaveClick = () => {
        // Save the dayValue and timeValue
        // Close the card
        setRescheduleClicked(false);
    };

    const handleCancelClick = () => {
        // Close the card without saving the information
        setRescheduleClicked(false);
    };
    
    const handleDoneClick = () => {
        // Close the component when "Mark as Done" is clicked
        setComponentVisible(false);
    };

    const handleDeleteClick = () => {
        // Close the component when "Delete" is clicked
        setComponentVisible(false);
    };

    if (!isComponentVisible) {
        return null;
    }

    if (isRescheduleClicked) {
        return (
            <div className={classes.rescheduleComponent}>
                <h2 className={classes.midTitle}>Task Estimation 10h</h2>
                <p  className={classes.textWithPadding}>"You will feel better because if you plan well, due dates won't be a problem anymore."</p>
                <p className={classes.dueDateText}>due date</p>
                <div className={classes.inputContainer}>
                <div className={classes.inputBoxContainer}>
                    <input
                    className={classes.inputBox}
                    placeholder="Day"
                    value={dayValue}
                    onChange={(e) => setDayValue(e.target.value)}
                    />
                </div>
                <div className={classes.inputBoxContainer}>
                    <input
                    className={classes.inputBox}
                    placeholder="Time"
                    value={timeValue}
                    onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>
                </div>
                <div className={classes.iconContainer}>
                <IconContext.Provider value={{ className: classes.icon }}>
                <div onClick={handleSaveClick} > <BiCheck/> </div>
                <div onClick={handleCancelClick}> <BiX color= "gray" /> </div>
                </IconContext.Provider>
                </div>
            </div>
        );
    }
    return (
        <div className={classes.dueDateComponent}>
            <h2 className={classes.title}>Due date task</h2>
            <div className={classes.options}>
                <button className={classes.btnOption} onClick={handleRescheduleClick}> <BiRefresh />  Reschedule</button>
                <button className={classes.btnOption} onClick={handleDoneClick}> <BiCheck />  Mark as Done</button>
                <button className={classes.btnOption} onClick={handleDeleteClick}> <BiBox />  Delete</button>
            </div>
        </div>
    );
};

export default DueDateComponent;