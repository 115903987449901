import React, { useState } from 'react';
import styles from './WeatherFavorite.module.css';
import weatherfav from '../rectangles2/weatherfav.png';


const WeatherFavorite = () => {
    const [favoriteWeathers, setFavoriteWeathers] = useState<string[]>([]);

    const weatherOptions = [
        {name: 'Rainy', emoji: '☔'},
        {name: 'Snowy', emoji: '❄️'},
        {name: 'Sunny', emoji: '☀️'},
        {name: 'Windy', emoji: '💨'},
        {name: 'Stormy', emoji: '🌩️'},
        {name: 'Partly Cloudy', emoji: '⛅'}
    ];

    const handleClick = (weather: string) => {
        // if the weather is already selected, remove it from the array
        if (favoriteWeathers.includes(weather)) {
            setFavoriteWeathers(favoriteWeathers.filter(w => w !== weather));
        } else if (favoriteWeathers.length < 2) {
            // else if less than two weathers are selected, add the new one
            setFavoriteWeathers([...favoriteWeathers, weather]);
        }
        // if two weathers are already selected, do nothing
    };

    return (
        <div className={styles.weatherFavorite}>
            <div className={styles.title}>
                What is your favorite weather(s)?
            </div>
            <div className={styles.iconGrid}>
                {weatherOptions.map((weather, index) => (
                    <div
                        key={index}
                        className={`${styles.iconContainer} ${favoriteWeathers.includes(weather.name) ? styles.selected : ''}`}
                        onClick={() => handleClick(weather.name)}
                    >
                        <div className={styles.icon}>
                            <span className={styles.weatherEmoji}>{weather.emoji}</span>
                        </div>
                        <p>{weather.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WeatherFavorite;
