import React, { FC, ReactElement, ReactNode, useContext, useLayoutEffect, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMeasure, useWindowSize } from 'react-use';
import ThemeContext from '../../contexts/themeContext';
import Button from '../../components/bootstrap/Button';
import Portal from '../Portal/Portal';
import useDarkMode from '../../hooks/useDarkMode';
import BreathComponent from '../../components/recs-calendar/Breath';
import Chain from '../../components/recs-calendar/chain-rule/Chain';
import PomogramComponent from '../../components/recs-calendar/pomogram/Pomogram';
import WeatherFavorite from '../../components/recs-calendar/weather/WeatherFavorite';
import TalkAboutTask from '../../components/recs-calendar/due-date/TalkAboutTask';
import WeatherHome from '../../components/recs-calendar/weather/WeatherHome';
import WeatherSuggestion from '../../components/recs-calendar/weather/WeatherSuggestion';
import CalendarRedLine from '../../components/recs-calendar/red-line/CalendarRedLine';
import DueDateComponent from '../../components/recs-calendar/due-date/DueDate';
import FocusBox from '../../components/focus/FocusBox';
import styles from './Header.module.css';
import breath from './breath.png';
import chain from './chain.png';
import task from './task.png';
import AuthContext from '../../contexts/authContext';


interface IHeaderLeftProps {
	children: ReactNode;
	className?: string;
}
export const HeaderLeft: FC<IHeaderLeftProps> = ({ children, className }) => {
	const initialComponentState = {
        breathComponent: false,
        chain: false,
        pomodoro: false,
        task: false,
        weather: null,
		dueDate: false,
		redLine: false,
    }
	const { userData } = useContext(AuthContext);

    const [components, setComponents] = useState(initialComponentState);

    const handleClick = (componentKey: string, value: any = true) => {
        if(components[componentKey] === value){
            setComponents({ ...initialComponentState });
        }else{
            setComponents({ ...initialComponentState, [componentKey]: value });
        }
    };

	const closeComponent = () => {
		setComponents(initialComponentState);
	  };
	
	  const componentRef = useRef(null);
	
	  useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
		  if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
			closeComponent();
		  }
		}
	
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
		  document.removeEventListener("mousedown", handleClickOutside);
		};
	  }, []); 

	  const [isPomodoroRunning, setIsPomodoroRunning] = useState(false);
	  const [timeLeft, setTimeLeft] = useState(60); // 25 minutes
  
	  const [currentSession, setCurrentSession] = useState('Work');
	  const [completedPomodoros, setCompletedPomodoros] = useState(0);
  
	  useEffect(() => {
		  let timer: NodeJS.Timeout | null = null;
		  if (isPomodoroRunning && timeLeft > 0) {
			  timer = setInterval(() => {
				  setTimeLeft((prevTime) => prevTime - 1);
			  }, 1000);
		  } else if (timeLeft === 0) {
			  setIsPomodoroRunning(false);
			  if (currentSession === 'Work') {
				  setCompletedPomodoros((prev) => prev + 1);
				  if (completedPomodoros % 4 === 0) {
					  setCurrentSession('Long Break');
					  setTimeLeft(90); // 15 minutes
				  } else {
					  setCurrentSession('Short Break');
					  setTimeLeft(30); // 5 minutes
				  }
			  } else {
				  setCurrentSession('Work');
				  setTimeLeft(60); // 25 minutes
			  }
		  }
		  return () => {
			  if (timer) clearInterval(timer);
		  };
	  }, [isPomodoroRunning, timeLeft, currentSession, completedPomodoros]);
  
	  const startPomodoro = (e: React.MouseEvent) => {
		  e.stopPropagation();
		  setIsPomodoroRunning(true);
		  if (currentSession === 'Work') {
			  setTimeLeft(60); // 25 minutes
		  } else if (currentSession === 'Short Break') {
			  setTimeLeft(300); // 5 minutes
		  } else if (currentSession === 'Long Break') {
			  setTimeLeft(900); // 15 minutes
		  }
	  };
  
	  const stopPomodoro = (e: React.MouseEvent) => {
		  e.stopPropagation();
		  setIsPomodoroRunning(false);
	  };

	  const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

	const [isFocusBoxOpen, setIsFocusBoxOpen] = useState(false);

    const toggleFocusBox = () => {
        setIsFocusBoxOpen((prev) => !prev);
    };


	return <div className={classNames('header-left', 'col-md', className)} ref={componentRef}>{children} 
			<div className={styles.greeting}>
				<h2>Welcome{userData.name ? `, ${userData.name}` : '!'} </h2>
				<h4>You can make magic happen</h4>
			</div>
			{/* <Button className={styles.buttonBreath} onClick={() => handleClick('breathComponent')}>
			<img src={breath} alt="Icon" className={styles.icon} />
			<span className={styles.tooltip}>Breath</span>
			</Button>
            <Button className={styles.buttonChain} onClick={() => handleClick('chain')}>
			<img src={chain} alt="Icon" className={styles.icon} />
			<span className={styles.tooltip}>Chain</span>
			</Button>
            <Button className={styles.buttonPomodoro} onClick={() => handleClick('pomodoro')}>
			<img src={task} alt="Icon" className={styles.icon} />
			<span className={styles.tooltip}>Pomodoro</span>
			</Button>
            <Button className={styles.buttonWeather} onClick={() => handleClick('weather')}>
			<img src={chain} alt="Icon" className={styles.icon} />
			<span className={styles.tooltip}>Weather</span>
			</Button>
            <Button className={styles.buttonTask} onClick={() => handleClick('task')}>
			<img src={chain} alt="Icon" className={styles.icon} />
			<span className={styles.tooltip}>Task</span>
			</Button>
			<Button className={styles.buttonDueDate} onClick={() => handleClick('dueDate')}>
			<img src={chain} alt="Icon" className={styles.icon} />
			<span className={styles.tooltip}>Due Date</span>
			</Button>
			<Button className={styles.buttonRedLine} onClick={() => handleClick('redLine')}>
				<img src={chain} alt="Icon" className={styles.icon} />
				<span className={styles.tooltip}>Red Line</span>
			</Button> */}
			<Button className={styles.buttonFocus} onClick={toggleFocusBox}>
                <div>Focus</div>
                {!isPomodoroRunning ? (
                    <Button className={styles.buttonStart} onClick={startPomodoro}>
                        Start
                    </Button>
                ) : (
                    <div className={styles.pomodoro}>
                        <div className={styles.timer}>{formatTime(timeLeft)}</div>
                        <Button className={styles.buttonStop} onClick={stopPomodoro}>
                            Stop
                        </Button>
                    </div>
                )}
            </Button>
			{isFocusBoxOpen && (
                <FocusBox
				onClose={toggleFocusBox}
				isPomodoroRunning={isPomodoroRunning}
				timeLeft={timeLeft}
				setIsPomodoroRunning={setIsPomodoroRunning}
				setTimeLeft={setTimeLeft}
				currentSession={currentSession}
				setCurrentSession={setCurrentSession}
				completedPomodoros={completedPomodoros}
				setCompletedPomodoros={setCompletedPomodoros}
			/>
			)}            {/* {isPomodoroRunning && (
                <div className={styles.pomodoro}>
                    <div className={styles.timer}>{formatTime(timeLeft)}</div>
                    <Button className={styles.buttonStop} onClick={stopPomodoro}>Stop</Button>
                </div>
            )} */}
            {components.breathComponent && <BreathComponent />}
            {components.chain && <Chain />}
            {components.pomodoro && <PomogramComponent />}
            {components.task && <TalkAboutTask />}
			{components.dueDate && <DueDateComponent />}
			{components.redLine && <CalendarRedLine />}
            {components.weather && (
                <div>
                    <Button className={styles.buttonWeatherHome} onClick={() => handleClick('weather', 'home')}>home</Button>
                    <Button className={styles.buttonWeatherFav} onClick={() => handleClick('weather', 'fav')}>fav</Button>
                    <Button className={styles.buttonWeatherSug} onClick={() => handleClick('weather', 'suggestion')}>sug</Button>

                    {components.weather === 'home' && <WeatherHome />}
                    {components.weather === 'fav' && <WeatherFavorite />}
                    {components.weather === 'suggestion' && <WeatherSuggestion />}
                </div>
            )}
			</div>;
};
HeaderLeft.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
HeaderLeft.defaultProps = {
	className: undefined,
};

interface IHeaderRightProps {
	children: ReactNode;
	className?: string;
}
export const HeaderRight: FC<IHeaderRightProps> = ({ children, className }) => {
	const [ref, { height }] = useMeasure<HTMLDivElement>();

	const root = document.documentElement;
	root.style.setProperty('--header-right-height', `${height}px`);

	return (
		<div ref={ref} className={classNames('header-right', 'col-md-auto', className)}>
		
		</div>
	);
};
HeaderRight.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
HeaderRight.defaultProps = {
	className: undefined,
};

interface IHeaderProps {
	children: ReactElement<IHeaderLeftProps> | ReactElement<IHeaderRightProps> | ReactNode;
	hasLeftMobileMenu?: boolean;
	hasRightMobileMenu?: boolean;
}
const Header: FC<IHeaderProps> = ({ children, hasLeftMobileMenu, hasRightMobileMenu }) => {
	const { themeStatus } = useDarkMode();

	const windowsWidth = useWindowSize().width;
	const [refMobileHeader, sizeMobileHeader] = useMeasure<HTMLDivElement>();
	const [refHeader, sizeHeader] = useMeasure<HTMLDivElement>();

	const root = document.documentElement;
	root.style.setProperty('--mobile-header-height', `${sizeMobileHeader.height}px`);
	root.style.setProperty('--header-height', `${sizeHeader.height}px`);

	const {
		asideStatus,
		setAsideStatus,
		leftMenuStatus,
		setLeftMenuStatus,
		rightMenuStatus,
		setRightMenuStatus,
	} = useContext(ThemeContext);

	useLayoutEffect(() => {
		if (
			(asideStatus || leftMenuStatus || rightMenuStatus) &&
			windowsWidth < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
		)
			document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	});

	return (
		<>
		<div  style={{ width: '98rem', height: '5.9375rem',  marginLeft: '60px' }}>
			<header ref={refMobileHeader} className='mobile-header'>
			
			</header>
			<header
				ref={refHeader}
				className={classNames('header', {
					'header-left-open': leftMenuStatus,
					'header-right-open': rightMenuStatus,
				})}>
				<div className='container-fluid'>
					<div className='row d-flex align-items-center'>
						{children}
					</div>
				</div>
			</header>
			</div>
		</>
	);
};
Header.propTypes = {
	children: PropTypes.node.isRequired,
	hasLeftMobileMenu: PropTypes.bool,
	hasRightMobileMenu: PropTypes.bool,
};
Header.defaultProps = {
	hasLeftMobileMenu: true,
	hasRightMobileMenu: true,
};

export default Header;
