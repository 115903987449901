import React from 'react';
import classes from './Breath.module.css';
import { BiPlayCircle, BiVolume } from 'react-icons/bi';
import styles from './BreathAnimation.module.css';
import { IconContext } from 'react-icons';

const BreathComponent = () => {
  const [isRunning, setIsRunning] = React.useState(false);
  const [selectedRectangle, setSelectedRectangle] = React.useState(null); // New state variable
  const smallCircleRef = React.useRef(null);


  const handleIconClick = (icon) => {
    console.log(`${icon} clicked`);
    // Add your action on icon click
  };
  
  let timeoutId = null;  // Declare this variable outside of the function

  const handleRectangleClick = (rectangle, time) => {
    if (timeoutId) clearTimeout(timeoutId);  // Clear the previous timer
  
    setSelectedRectangle(rectangle);
    setIsRunning(true);
  
    timeoutId = setTimeout(() => {
      setIsRunning(false);
      setSelectedRectangle(null);
    }, time);
  };
  

  React.useEffect(() => {
    if (selectedRectangle) {
      const circleElement = smallCircleRef.current;
      let angle = 0;

      // Calculate speed based on selected time
      // Assuming you pass time in milliseconds to handleRectangleClick
      const time = selectedRectangle === 'rectangle1' ? 60000 : 120000;
      const speed = 2 * Math.PI / time * 10;  // Full circle within selected time

      const intervalId = setInterval(() => {
        angle += speed;

        const x = 51.15 + 51.15 * Math.cos(angle);
        const y = 51.15 + 51.15 * Math.sin(angle);

        if (circleElement) {
          circleElement.style.left = `${x}%`;
          circleElement.style.top = `${y}%`;
        }
      }, 10);

      return () => clearInterval(intervalId);
    }
  }, [selectedRectangle]);



  return (
    <div className={classes.card}>
      <h1 className={classes.caption}>Breath</h1>
      <div className={`${classes.outerCircle} ${isRunning ? styles.running : ''}`}>
        <div ref={smallCircleRef} className={classes.asmallCircle}>
        </div>
        <div className={classes.middleCircle}>
          <div className={classes.innerCircle}>
            <div className={classes.smallCircle} >
              <IconContext.Provider value={{ className: classes.playButton }}>
                <BiPlayCircle color='white' />
              </IconContext.Provider>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.rectangleContainer}>
        <div
          className={`${classes.rectangle} ${selectedRectangle === 'rectangle1' ? classes.rectangle1 : ''}`}
          onClick={() => handleRectangleClick('rectangle1', 60000)}
        >
          <span className={classes.alarmIcon}>⏰</span>
          <span>1 min</span>
        </div>
        <div
          className={`${classes.rectangle} ${selectedRectangle === 'rectangle2' ? classes.rectangle1 : ''}`}
          onClick={() => handleRectangleClick('rectangle2', 120000)}
        >
          <span className={classes.alarmIcon}>⏰</span>
          <span>2 min</span>
        </div>
      </div>
      <div className={classes.hrectangleContainer}>
        <div className={classes.hrectangle}>
          <span className={classes.icon} onClick={() => handleIconClick('fire')}>🔥</span>
          <span className={classes.icon} onClick={() => handleIconClick('wave')}>🌊</span>
          <span className={classes.icon} onClick={() => handleIconClick('rain')}>🌧️</span>
          <span className={classes.icon} onClick={() => handleIconClick('meditating')}>🧘</span>
          <span className={classes.icon} onClick={() => handleIconClick('piano')}>🎹</span>
          <span className={classes.icon} onClick={() => handleIconClick('bird')}>🐦</span>
        </div>
      </div>
    </div>
  );
};

export default BreathComponent;