import React from 'react';
import classes from './WeatherSuggestion.module.css';
import weathersug from '../rectangles2/weathersug.png';


const WeatherSuggestion = () => {
  return (
    <div className={classes.weatherSuggestion}>
      <div className={classes.title}>Suggestion for you!</div>
      <div className={classes.rectangle}>
        10 amazing activities you can do in boring and rainy weather :<br/>
        1- Go to cinema !<br/>
        2- Start a book you love
      </div>
      <div className={classes.cloudIcon}>🌧️</div>
    </div>
  );
};

export default WeatherSuggestion;