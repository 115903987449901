import React from 'react';
import classes from './WeatherHome.module.css';
import weatherhome from '../rectangles2/weatherhome.png';
import {BiRefresh} from 'react-icons/bi';


const WeatherHome = () => {
    const currentDate = new Date();

    const currentLocation = 'New York';

    const formattedDate = currentDate.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });

    const weatherData = [
        { emoji: '🌤️', degree: 22, hour: 'Now' },
        { emoji: '⛅', degree: 20, hour: '2 PM' },
        { emoji: '🌦️', degree: 18, hour: '3 PM' },
        { emoji: '🌧️', degree: 16, hour: '4 PM' },
    ];

  return (
    <div className={classes.weatherHome}>
      <div className={classes.titleRectangle}>Warning</div>
      <div className={`${classes.locationIcon} ${classes.currentLocation}`}>📍{currentLocation}</div>
      <div className={classes.rectangle}>
        <div className={classes.dateTime}>{formattedDate}</div>
        It’s rainy tomorow but you have a concert event for tomorrow on your calendar/to do.
      </div>
      <div className={classes.refreshIcon}>
      <BiRefresh />
      </div>
      <div className={classes.weatherData}>
          {weatherData.map((data, index) => (
            <div className={classes.weatherItem} key={index}>
              <div className={classes.weatherEmoji}>{data.emoji}</div>
              <div className={classes.weatherDegree}>{data.degree}°</div>
              <div className={classes.weatherHour}>{data.hour}</div>
            </div>
          ))}
        </div>
    </div>
  );
};

export default WeatherHome;