import React, { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IUserProps } from './UserData';
import axios from 'axios';
import UserImage4 from '../assets/img/wanna/wanna4.png';
import UserImage4Webp from '../assets/img/wanna/wanna4.webp';
import { BACKEND_URL } from '../config';



export interface IAuthContextProps {
	uid: string;
	setUid?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
    const [uid, setUid] = useState<string>(localStorage.getItem('facit_authUid') || '');
    const [userData, setUserData] = useState<Partial<IUserProps>>({});

    useEffect(() => {
        localStorage.setItem('facit_authUid', uid);
    }, [uid]);

    const fetchUser = useCallback(async () => {
        if (!uid) return; // Guard clause for empty uid
        try {
            const response = await axios.get(`${BACKEND_URL}/user?id=${uid}`);
            if (response.status === 200) {
                const user: IUserProps = {
                    uid: uid,
                    name: response.data.name,
                    isOnline: true,
                    src: UserImage4,
                    srcSet: UserImage4Webp
                };
                setUserData(user);
            }
        } catch (error) {
            console.error(error);
        }
    }, [uid]);

    useEffect(() => {
        console.log('Current UID:', uid);
        if (uid) {
            fetchUser().catch((error) => console.log(error));
        } else {
            setUserData({});
        }
    }, [uid, fetchUser]);

    const value = useMemo(
        () => ({
            uid,
            setUid,
            userData,
        }),
        [uid, userData],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
