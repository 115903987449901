import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={width} height={height} viewBox="0 0 300.000000 150.000000"
 preserveAspectRatio="xMidYMid meet">

	<g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)"
	fill="#000000" stroke="none">
	<path d="M1235 921 c-40 -10 -87 -35 -106 -57 -52 -59 -68 -159 -36 -223 9
	-19 17 -41 17 -49 0 -11 9 -7 30 13 29 28 30 31 24 96 l-7 68 37 37 37 37 68
	-7 c65 -6 68 -5 96 24 23 24 25 30 11 30 -9 0 -29 6 -44 14 -37 18 -91 25
	-127 17z"/>
	<path d="M819 911 c-14 -5 -38 -16 -52 -26 -24 -15 -27 -15 -27 -2 0 19 -33
	31 -66 23 l-24 -6 2 -188 3 -187 40 0 40 0 5 128 c5 120 7 130 29 148 31 26
	83 25 107 -2 16 -18 20 -42 24 -148 l5 -126 40 0 40 0 3 140 c2 118 -1 145
	-15 174 -31 59 -96 89 -154 72z"/>
	<path d="M2069 911 c-67 -22 -110 -72 -128 -151 -20 -89 24 -186 102 -227 44
	-22 119 -20 162 6 19 11 35 16 35 10 0 -19 48 -33 75 -21 l25 12 0 173 c0 121
	-4 177 -12 185 -19 19 -66 15 -80 -7 -11 -19 -12 -19 -39 1 -30 22 -100 32
	-140 19z m127 -106 c51 -35 55 -125 9 -171 -18 -18 -35 -24 -67 -24 -55 0 -84
	20 -99 69 -14 47 1 103 34 126 27 19 96 19 123 0z"/>
	<path d="M1533 894 c-8 -20 113 -345 134 -361 9 -7 29 -13 43 -13 14 0 34 6
	43 13 21 16 142 341 134 361 -8 19 -70 22 -85 4 -5 -7 -27 -66 -48 -130 -21
	-65 -41 -118 -44 -118 -3 0 -23 53 -44 118 -21 64 -43 123 -48 130 -15 18 -77
	15 -85 -4z"/>
	<path d="M1408 839 c-24 -31 -26 -40 -21 -100 5 -62 4 -66 -25 -96 -36 -36
	-69 -44 -122 -31 -35 9 -42 7 -76 -18 -23 -18 -31 -30 -23 -32 8 -2 29 -10 47
	-19 85 -38 164 -24 228 41 65 64 79 143 41 228 -9 18 -17 39 -19 47 -3 10 -13
	4 -30 -20z"/>
	</g>
	</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 400,
	height: 200,
};

export default Logo;
