import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Chain.module.css';
import { BACKEND_URL } from '../../../config';
import { format } from 'date-fns';
import { priceFormat } from '../../../helpers/helpers';

const Chain: React.FC = () => {
  const [target, setTarget] = useState<string>('');
  const [days, setDays] = useState<number>(50);
  const [circles, setCircles] = useState<number[]>([]);
  const [selectedCircles, setSelectedCircles] = useState<number[]>([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState<{ x: number, y: number }[]>([]);
  const [chainId, setChainId] = useState<number | null>(null);
  const [lastClickedDate, setLastClickedDate] = useState<string | null>(null);
  const [clickedToday, setClickedToday] = useState<boolean>(false);

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const today = formatDate(new Date());

    if (lastClickedDate === today) {
      setClickedToday(true);
    } else {
      setClickedToday(false);
      setChainId(null);
    }

    let newCircles = [];
    for (let i = 1; i <= days; i++) {
      newCircles.push(i);
    }
    setCircles(newCircles);

  }, [days, lastClickedDate]);

  const toggleSelected = (circle: number, event: React.MouseEvent<HTMLDivElement>) => {
    if (clickedToday) return;

    const today = formatDate(new Date());
    setLastClickedDate(today);
    setClickedToday(true);

    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const coords = { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 };

    const param = { "user_id": 1 };

    if (chainId === null) {
      const data = {
        "chain_day": circle,
        "target_day": days,
        "target": target,
        "last_checked": formatDate(new Date())
      };

      axios.post(BACKEND_URL + 'chain/add', data, { params: param })
        .then(response => {
          console.log(response);
          setChainId(response.data.chain_id);
        })
        .catch(error => {
          console.log(error);
        });

    } else {
      const updatedChainId = chainId + 1;
      const paramWithChainId = { ...param, chain_id: updatedChainId };

      axios.put(BACKEND_URL + 'chain/update', {}, { params: paramWithChainId })
        .then(response => {
          console.log(response);
          setChainId(updatedChainId);
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (selectedCircles.includes(circle)) {
      setSelectedCircles(selectedCircles.filter(item => item !== circle));
      setSelectedCoordinates(selectedCoordinates.filter(coord =>
        coord.x !== coords.x || coord.y !== coords.y
      ));
    } else {
      setSelectedCircles([...selectedCircles, circle]);
      setSelectedCoordinates([...selectedCoordinates, coords]);
    }
  };

  const resetChain = () => {
    setTarget('');
    setDays(50);
    setSelectedCircles([]);
    setSelectedCoordinates([]);
    setChainId(null);
    setLastClickedDate(null);
    setClickedToday(false);
  };

  const renderLines = () => {
    if (selectedCoordinates.length === 2) {
      const [start, end] = selectedCoordinates;
      const length = Math.hypot(end.x - start.x, end.y - start.y);
      const angle = Math.atan2(end.y - start.y, end.x - start.x) * 180 / Math.PI;
      const transform = `rotate(${angle}deg)`;

      return (
        <div
          className={styles.line}
          style={{
            width: `${length}px`,
            left: `${start.x}px`,
            top: `${start.y}px`,
            transform
          }}
        />
      );
    }
    return null;
  };

  return (
    <div className={styles.chain}>
      <h1 className={styles.title}>Don't Break the Chain!</h1>
      <div className={styles.inputContainer}>
        <input
          className={styles.inputText}
          type="text"
          value={target}
          onChange={(e) => {
            setTarget(e.target.value);
            setChainId(null);
          }}
          placeholder="Your target"
        />
        <input
          className={styles.inputNumber}
          type="number"
          value={days}
          onChange={(e) => {
            setDays(Number(e.target.value));
            setChainId(null);
          }}
          placeholder="How many days are you targeting?"
        />
      </div>
      <div className={styles.circleContainer}>
        {circles.map((circle) => (
          <div
            key={circle}
            className={`${styles.circle} ${selectedCircles.includes(circle) ? styles.selected : ''}`}
            onClick={(e) => toggleSelected(circle, e)}
          >
            {circle}
          </div>
        ))}
      </div>
      <button onClick={resetChain}>Reset</button>

    </div>
  );
}

export default Chain;
