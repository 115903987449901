import React, {useState, useEffect, useRef} from 'react';
import styles from './Pomogram.module.css';
import { BiPause, BiPlay, BiReset, BiCheck } from 'react-icons/bi';
import { IconContext } from 'react-icons';

const PomogramComponent = () => {
    const [counter, setCounter] = useState(1/6 * 60); // counter in seconds
    const [isActive, setIsActive] = useState(false);
    const [circleColor, setCircleColor] = useState('');
    const [tasks, setTasks] = useState([
      { id: 1, title: 'Task 1', completed: false },
      { id: 2, title: 'Task 2', completed: false },
    ]);
    const progressCircleRef = useRef(null);
    const backgroundCircleRef = useRef(null); // add this at the top of your component

  
    const toggleTask = (id) => {
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === id ? { ...task, completed: !task.completed } : task
        )
      );
    };
  
    useEffect(() => {
      let intervalId;
    
      if (isActive && counter > 0) {
        intervalId = setInterval(() => {
          setCounter(counter - 1);
    
          const percentage = ((1/6 * 60) - counter) / (1/6 * 60);
          const circumference = 2 * Math.PI * 48; // 2πr
          const offset = circumference * (1 - percentage);
    
          progressCircleRef.current.style.strokeDasharray = `${circumference}px`;
          progressCircleRef.current.style.strokeDashoffset = `${offset}px`;
          
        }, 1000);
      } else if (!isActive && counter === 0) {
        progressCircleRef.current.style.stroke = 'green';  // Final completed circle color
        backgroundCircleRef.current.style.stroke = 'green';  // Make the background circle also green
        setIsActive(false);
      } else {
        setIsActive(false);
      }
    
      return () => clearInterval(intervalId);
    }, [isActive, counter]);
    
    

    const toggleCounter = () => {
        setIsActive(!isActive);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;

    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  const date = new Date();
  const dayInitial = date.toLocaleString('en-US', { weekday: 'long' })[0];
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'long'});
  const year = date.getFullYear();

  const resetCounter = () => {
    setIsActive(false);
    setCounter(1/6 * 60);
  }

  return (
    <div className={styles.root}>
      <div className={styles.rectangle}>
        I see you haven’ read a book today,<br/>Let's get this done!
      </div>
      <div>
        {month} {year}
      </div>
      <div className={styles.oval}>
        <div>{dayInitial}</div>
        <div>{day}</div>
      </div>
      <div className={styles.ring} onClick={toggleCounter}>
        <svg className={styles.progressSvg} viewBox="0 0 100 100">
          <circle ref={backgroundCircleRef} cx="50" cy="50" r="48" strokeWidth="5" stroke="#6C5DD3" fill="none" />
          <circle ref={progressCircleRef} cx="50" cy="50" r="48" strokeWidth="5" stroke="black" fill="none" />
          <text x="50" y="50" textAnchor="middle" dominantBaseline="middle" fontSize="20" fill="white" writing-mode="lr">
            {isActive ? formatTime(counter) : 'GO'}
          </text>
        </svg>
      </div>
        <div className={styles.controls}>
                <button 
                    className={`${styles.controlButton} ${isActive && styles.active}`}
                    onClick={toggleCounter}
                    onMouseEnter={() => setCircleColor('rgba(0,0,0,0.2)')}
                    onMouseLeave={() => setCircleColor('')}
                >
                    {isActive ? <BiPause /> : <BiPlay />}
                </button>
                <button 
                    className={styles.controlButton}
                    onClick={resetCounter}
                >
                    <BiReset />
                </button>
            </div>
            <div className={styles.finishedTitle}>Finished</div>
            <div className={styles.taskContainer}>
              {tasks.map((task) => (
                <div
                  key={task.id}
                  className={`${styles.task} ${
                    task.completed ? styles.completed : ''
                  }`}
                  onClick={() => toggleTask(task.id)}
                >
                  <div className={styles.taskRing}></div>
                  <IconContext.Provider value={{ className: styles.checkIcon }}>
                    {task.completed && <BiCheck />}
                  </IconContext.Provider>
                  <div className={styles.taskTitle}>{task.title}</div>
                </div>
              ))}
            </div>
    </div>
  );
}

export default PomogramComponent;
