import React, { useState } from 'react';
// import Clock from 'react-clock';
// import TimePicker from 'react-time-picker';
import styles from './CalendarRedLine.module.css';

const CalendarRedLine = () => {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const timeOptions = ["08:30", "09:30", "10:00", "10:30"];
    const [selectedTime, setSelectedTime] = useState(null);
    const [isOpen, setIsOpen] = useState(true);
  
    const handleTimeOptionClick = (time) => {
      setSelectedTime(time);
    };
  
    const handleStartTimeChange = (e) => {
      setStartTime(e.target.value);
    };
  
    const handleEndTimeChange = (e) => {
      setEndTime(e.target.value);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!isOpen) {
        return null; // Render nothing if closed
    }

    return (
        <div>
            <div className={styles.calendarRedLine}>
                {/* <button className={styles.closeButton} onClick={handleClose}>
                    X
                </button> */}
                <p className={styles.calendarText}>You don’t have free time<br/> between 15.00-23.00</p>
                <p className={styles.secondText}>Do you need a change ?</p>
                <div className={styles.line}></div>
                <div className={styles.timeInputContainer}>
                    <input
                        className={styles.timeInput}
                        type="text"
                        placeholder="Start time"
                        value={startTime}
                        onChange={handleStartTimeChange}
                    />
                    <input
                        className={styles.timeInput}
                        type="text"
                        placeholder="End time"
                        value={endTime}
                        onChange={handleEndTimeChange}
                    />
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.timeOptions}>
                        
                    {timeOptions.map(time => (
                    <div
                        key={time}
                        className={`${styles.timeOption} ${selectedTime === time ? styles.selected : ''}`}
                        onClick={() => handleTimeOptionClick(time)}
                    >
                        {time}
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CalendarRedLine;
