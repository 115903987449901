import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router";

function SignIn(props) {

  const nav=useNavigate();

  useEffect(()=>{
    if(localStorage.getItem('JWT')!== null){
      return nav("/home")
    }
  },[])
  return (
    <div>
      <button 
      onClick={(e)=>props.login(e)} 														
      color='warning'
      style={{ background:"none", borderRadius:"13px" }}
      className='w-100 py-3'>
        <img
          style={{ width: "28px", height: "25px", paddingRight:"10px" }}
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
          alt="Google Logo"
        />
        Login with Google
      </button>
    </div>
  );
}

export default SignIn;