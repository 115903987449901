import axios from "axios";
import jwtDecode from "jwt-decode";
import { BACKEND_URL } from "./config";

const setUidToLocalStorage = () => {
    let decodedToken: any = jwtDecode(localStorage.getItem('access_token'))
    localStorage.setItem('facit_authUid', decodedToken.sub)
}

const refreshToken = () => {
    axios.post(BACKEND_URL + '/refresh', {}, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('refresh_token')
        }
    }).then((res) => {
        localStorage.setItem('access_token', res.data.access_token);
    }).catch((error) => { throw error })
}

const getHeadersIncludesAuthToken = () => {
    let token = localStorage.getItem('access_token')
    let decodedToken: any = jwtDecode(token)
    if (new Date().getTime() <= decodedToken.exp) {
        try {
            refreshToken();
            token = localStorage.getItem('access_token')
        } catch (error) {
            console.log(error)
        }
    }
    let auth_token = 'Bearer ' + token;
    let headers = {
        Authorization: auth_token,
        "Content-Type": "application/json",
    }
    return headers;
}

export { getHeadersIncludesAuthToken, setUidToLocalStorage }