import React, { useState } from 'react';
import classes from './TalkAboutTask.module.css';
import { BiChevronDown, BiChevronUp, BiRefresh, BiTrash, BiCalendar, BiCalendarMinus } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { IconContext } from 'react-icons';

const TalkAboutTask = () => {
  const [isComponentOpen, setComponentOpen] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [isExpanded, setExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedRect, setSelectedRect] = useState('');
  const [isEditable, setEditable] = useState(false);
  const [customText, setCustomText] = useState('');
  const [savedText, setSavedText] = useState('');

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleRectangleClick(customText);
      setEditable(false);
    }
  };

  const handleRectangleClick = (value) => {
    if (value === '+') {
      setEditable(true);
      setSelectedRect('');
    } else {
      setSelectedRect(value);
      setEditable(false);
    }
  };

  const handleTextChange = (event) => {
    setCustomText(event.target.value);
  };

  const handleArrowClick = () => {
    setExpanded(!isExpanded);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setExpanded(false);
  };

  const handleSaveClick = () => {
    // Save the inputValue here
    setComponentOpen(false);
  };

  const handleCancelClick = () => {
    setComponentOpen(false);
  };

  if (!isComponentOpen) {
    return null; // Return null to hide the component
  }


  return (
    <div className={classes.talkAboutTask}>
      <h2 className={classes.title}>We need to talk <br /> about this task: </h2>
      <div className={classes.titleUnderline}></div>
      <IconContext.Provider value={{ className: classes.icon }}>
        <div className={classes.iconWithText}>
          <FiEdit />
          <div className={classes.rectangle}>
            Reduce frequency
          </div>
        </div>
      </IconContext.Provider>
      <IconContext.Provider value={{ className: classes.icon }}>
        <div className={classes.iconWithText}>
          <BiTrash />
          <div className={classes.rectangle} onClick={handleArrowClick}>
            {selectedOption && isExpanded ? selectedOption : 'Delete only this task'}
            <span className={classes.arrowIcon}>
              {isExpanded ? <BiChevronUp /> : <BiChevronDown />}
            </span>
          </div>
        </div>
      </IconContext.Provider>
      {isExpanded && (
        <div className={classes.expandedRectangle}>
          <div
            className={`${classes.option} ${selectedOption === 'Delete this and the following task' ? classes.selectedOption : ''}`}
            onClick={() => handleOptionClick('Delete this and the following task')}
          >
            Delete this and the following task
          </div>
          <div
            className={`${classes.option} ${selectedOption === 'Delete all of this task' ? classes.selectedOption : ''}`}
            onClick={() => handleOptionClick('Delete all of this task')}
          >
            Delete all of this task
          </div>
        </div>
      )}
      <IconContext.Provider value={{ className: classes.icon }}>
        <div className={classes.iconWithText}>
          <BiCalendar />
          <div className={classes.rectangle}>
            Arrange the duration of the task
          </div>
        </div>
      </IconContext.Provider>

      <IconContext.Provider value={{ className: classes.icon }}>
        <div className={classes.iconWithText}>
          <BiCalendarMinus />
          <div className={classes.rectangle}>
            Remove from tasks for a while
          </div>
        </div>
      </IconContext.Provider>
      <div className={classes.smallRectangleContainer}>
        <div
          className={`${classes.smallRectangle} ${selectedRect === '1 week' ? classes.selectedRectangle : ''}`}
          onClick={() => handleRectangleClick('1 week')}
        >
          1 week
        </div>
        <div
          className={`${classes.smallRectangle} ${selectedRect === '1 month' ? classes.selectedRectangle : ''}`}
          onClick={() => handleRectangleClick('1 month')}
        >
          1 month
        </div>
        <div
          className={`${classes.smallRectangle} ${selectedRect === '2 month' ? classes.selectedRectangle : ''}`}
          onClick={() => handleRectangleClick('2 month')}
        >
          2 month
        </div>
        {isEditable ? (
          <input
            className={classes.smallRectangle}
            value={customText}
            onChange={handleTextChange}
            onKeyPress={handleKeyPress}
          />
        ) : (
          <div
            className={classes.smallRectangle}
            onClick={() => handleRectangleClick('+')}
          >
            +
          </div>
        )}
      </div>
      <IconContext.Provider value={{ className: classes.icon }}>
        <div className={classes.iconWithText}>
          <BiRefresh />
          <div className={`${classes.rectangle} ${classes.rectangleLarge}`}> Try to habit this task:<br />Arrange the duration to 1 minute and after each recurrence add 1 minute
          </div>
        </div>

      </IconContext.Provider>
      <div className={classes.buttonContainer}>
        <button className={classes.cancelButton} onClick={handleCancelClick}>Cancel</button>
        <button className={classes.okButton} onClick={handleSaveClick}>OK</button>
      </div>
    </div>
  );
};

export default TalkAboutTask;