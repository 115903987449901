import React, { FC, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/bootstrap/Button';
import { BiExpand } from 'react-icons/bi';
import { FiMenu } from 'react-icons/fi';
import styles from './FocusBox.module.css';
import axios from 'axios';
import { BACKEND_URL } from "../../config";

interface FocusBoxProps {
    onClose: () => void;
    isPomodoroRunning: boolean;
    timeLeft: number;
    setIsPomodoroRunning: React.Dispatch<React.SetStateAction<boolean>>;
    setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
    currentSession: string;
    setCurrentSession: React.Dispatch<React.SetStateAction<string>>;
    completedPomodoros: number;
    setCompletedPomodoros: React.Dispatch<React.SetStateAction<number>>;
}

const FocusBox: FC<FocusBoxProps> = ({
    onClose,
    isPomodoroRunning,
    timeLeft,
    setIsPomodoroRunning,
    setTimeLeft,
    currentSession,
    setCurrentSession,
    completedPomodoros,
    setCompletedPomodoros,
}) => {
    const boxRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const themeMenuRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [isExpanded, setIsExpanded] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isThemeOpen, setIsThemeOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState<string | null>(null);
    const [selectedWallpaper, setSelectedWallpaper] = useState<string | null>(null);
    const [workDuration, setWorkDuration] = useState(60);
    const [shortBreakDuration, setShortBreakDuration] = useState(30);
    const [longBreakDuration, setLongBreakDuration] = useState(90);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [onlineUsers, setOnlineUsers] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [isPomodoroPaused, setIsPomodoroPaused] = useState(false);
    const [currentPomodoroId, setCurrentPomodoroId] = useState<number | null>(null);

    const api = axios.create({
        baseURL: BACKEND_URL,
        withCredentials: true
      });

    const [userStats, setUserStats] = useState({
        streak: 0,
        totalPomodoros: 0,
        rank: 0
    });

    // useEffect(() => {
    //     const fetchUserStats = async () => {
    //         try {
    //             const userId = localStorage.getItem('facit_authUid');
    //             const response = await axios.get(`${BACKEND_URL}/api/pomodoro/start?user_id=${userId}`);
    //             setUserStats(response.data);
    //         } catch (error) {
    //             console.error('Error fetching user stats:', error);
    //         }
    //     };

    //     fetchUserStats();
    // }, []);

    const fetchUserStats = async () => {
        try {
            const userId = localStorage.getItem('facit_authUid');

            const response = await api.get(`${BACKEND_URL}/api/user/stats?user_id=${userId}`);
            setUserStats(response.data);
    
        } catch (error) {
            console.error('Error fetching user stats:', error);
        }
    };
    
    useEffect(() => {
        fetchUserStats();
    }, []);

    useEffect(() => {
        const updateStatus = async () => {
          try {
            const userId = localStorage.getItem('facit_authUid');
            const response = await axios.post(`${BACKEND_URL}/user/update_status`, { user_id: userId });
            console.log('Status update response:', response.data);
          } catch (error) {
            console.error('Error updating status:', error);
          }
        };
      
        updateStatus(); // Update immediately on mount
        const interval = setInterval(updateStatus, 30000); // Update every minute
      
        return () => clearInterval(interval);
      }, []);

    // useEffect(() => {
    //     const fetchOnlineUsers = async () => {
    //         try {
    //             const response = await axios.get('/user/get_online_users');
    //             setOnlineUsers(response.data.online_users);
    //         } catch (error) {
    //             console.error('Error fetching online users:', error);
    //         }
    //     };

    //     fetchOnlineUsers();
    //     const interval = setInterval(fetchOnlineUsers, 60000);
    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        const fetchOnlineUsers = async () => {
          try {
            const response = await axios.get(`${BACKEND_URL}/user/get_online_users`);
            console.log('online users', response.data)
            setOnlineUsers(response.data.online_users);
          } catch (error) {
            console.error('Error fetching online users:', error);
          }
        };
      
        fetchOnlineUsers();
        const interval = setInterval(fetchOnlineUsers, 60000);
        return () => clearInterval(interval);
      }, []);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (isPomodoroRunning && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            setIsPomodoroRunning(false);
            setIsPomodoroPaused(false);
            if (currentSession === 'Work') {
                completePomodoro();
                setCompletedPomodoros((prev) => prev + 1);
                if (completedPomodoros % 4 === 0) {
                    setCurrentSession('Long Break');
                    setTimeLeft(longBreakDuration);
                } else {
                    setCurrentSession('Short Break');
                    setTimeLeft(shortBreakDuration);
                }
            } else {
                setCurrentSession('Work');
                setTimeLeft(workDuration);
            }
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [isPomodoroRunning, timeLeft, setTimeLeft, setIsPomodoroRunning, currentSession, setCurrentSession, completedPomodoros, setCompletedPomodoros, workDuration, shortBreakDuration, longBreakDuration]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setIsThemeOpen(false);
    };

    const toggleTheme = () => {
        setIsThemeOpen(!isThemeOpen);
        setIsMenuOpen(false);
    };

    const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen);
        setIsMenuOpen(false);
    };

    const stopPomodoro = () => {
        setIsPomodoroRunning(false);
        setIsPomodoroPaused(true);
    };

    const startPomodoro = async (e: React.MouseEvent) => {
        e.stopPropagation();
        try {

            const userId = localStorage.getItem('facit_authUid');
            console.log('Starting Pomodoro for user ID:', userId); // Debugging line

            const response = await api.post(`${BACKEND_URL}/api/pomodoro/start?user_id=${userId}`);
            setCurrentPomodoroId(response.data.id);
            setIsPomodoroRunning(true);
            setIsPomodoroPaused(false);
            if (currentSession === 'Work') {
                setTimeLeft(workDuration);
            } else if (currentSession === 'Short Break') {
                setTimeLeft(shortBreakDuration);
            } else if (currentSession === 'Long Break') {
                setTimeLeft(longBreakDuration);
            }
            fetchUserStats();
        } catch (error) {
            console.error('Error starting Pomodoro:', error);
        }
    };

    // const fetchUserStats = async () => {
    //     try {
    //         const userId = localStorage.getItem('facit_authUid');
    //         const response = await axios.get(`${BACKEND_URL}/api/user/stats?user_id=${userId}`);
    //         setUserStats(response.data);
    //     } catch (error) {
    //         console.error('Error fetching user stats:', error);
    //     }
    // };

    const completePomodoro = async () => {
        if (currentPomodoroId) {
            try {
                const userId = localStorage.getItem('facit_authUid');
                await axios.post(`${BACKEND_URL}/api/pomodoro/complete?user_id=${userId}`, { pomodoro_id: currentPomodoroId });
                setCurrentPomodoroId(null);
                setCompletedPomodoros((prev) => prev + 1);
                // Fetch updated stats after completing a Pomodoro
                fetchUserStats();
            } catch (error) {
                console.error('Error completing Pomodoro:', error);
            }
        }
    };

    const resumePomodoro = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsPomodoroRunning(true);
        setIsPomodoroPaused(false);
        fetchUserStats();

    };

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        if (boxRef.current) {
            const rect = boxRef.current.getBoundingClientRect();
            setOffset({
                x: e.clientX - rect.left,
                y: e.clientY - rect.top,
            });
        }
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (isDragging && boxRef.current) {
            boxRef.current.style.left = `${e.clientX - offset.x}px`;
            boxRef.current.style.top = `${e.clientY - offset.y}px`;
        }
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node) && !boxRef.current?.contains(event.target as Node)) {
            setIsMenuOpen(false);
        }
        if (themeMenuRef.current && !themeMenuRef.current.contains(event.target as Node) && !boxRef.current?.contains(event.target as Node)) {
            setIsThemeOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleCategorySelect = (category: string) => {
        setSelectedCategory(category);
        setIsMenuOpen(false);
    };

    const filteredUsers = selectedCategory
        ? onlineUsers.filter(user => user.category === selectedCategory)
        : onlineUsers;

    return (
        <div
            className={`${styles.focusBox} ${isExpanded ? styles.expanded : ''}`}
            ref={boxRef}
            onMouseDown={handleMouseDown}
            style={{
                position: 'absolute',
                top: '100px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: selectedColor || 'white',
                backgroundImage: selectedWallpaper || 'none'
            }}
        >
            <div className={styles.header}>
                <div className={styles.leftButtons}>
                    <Button className={styles.menuButton} onClick={toggleMenu}>
                        <FiMenu />
                    </Button>
                    <Button className={styles.expandButton} onClick={toggleExpand}>
                        <BiExpand />
                    </Button>
                </div>
                <Button className={styles.closeButton} onClick={onClose}>
                    X
                </Button>
            </div>

            {isMenuOpen && (
                <div className={styles.menu} ref={menuRef}>
                    <div className={styles.menuItem} onClick={toggleTheme}>Theme</div>
                    <div className={styles.menuItem} onClick={() => setIsMenuOpen(false)}>Statistics</div>
                    <div className={styles.menuItem} onClick={() => setIsMenuOpen(false)}>Breath</div>
                    <div className={styles.menuItem} onClick={toggleSettings}>Settings</div>
                </div>
            )}

            {isThemeOpen && (
                <div className={styles.themeMenu} ref={themeMenuRef}>
                    {/* Add theme options here */}
                </div>
            )}

            {isSettingsOpen && (
                <div className={styles.settingsForm}>
                    <label>
                        Focus Length (min):
                        <input type="number" value={workDuration / 60} onChange={(e) => setWorkDuration(Number(e.target.value) * 60)} />
                    </label>
                    <label>
                        Short Break Duration (min):
                        <input type="number" value={shortBreakDuration / 60} onChange={(e) => setShortBreakDuration(Number(e.target.value) * 60)} />
                    </label>
                    <label>
                        Long Break Duration (min):
                        <input type="number" value={longBreakDuration / 60} onChange={(e) => setLongBreakDuration(Number(e.target.value) * 60)} />
                    </label>
                    <button onClick={toggleSettings}>Save</button>
                </div>
            )}

            <div className={styles.timer}>
                <div className={styles.timerDisplay}>{formatTime(timeLeft)}</div>
                {!isPomodoroRunning && !isPomodoroPaused ? (
                    <Button className={styles.buttonStart} onClick={startPomodoro}>
                        Start
                    </Button>
                ) : (
                    <div className={styles.pomodoro}>
                        {isPomodoroPaused ? (
                            <Button className={styles.buttonResume} onClick={resumePomodoro}>
                                Resume
                            </Button>
                        ) : (
                            <Button className={styles.buttonStop} onClick={stopPomodoro}>
                                Stop
                            </Button>
                        )}
                    </div>
                )}
            </div>

            <div className={styles.userStats}>
                <div className={styles.statItem}>
                    <span className={styles.statIcon}>🔥</span>
                    <div className={styles.statContent}>
                        <span className={styles.statValue}>{userStats.streak}</span>
                        <span className={styles.statLabel}>Day Streak</span>
                    </div>
                </div>
                <div className={styles.statItem}>
                    <span className={styles.statIcon}>🍅</span>
                    <div className={styles.statContent}>
                        <span className={styles.statValue}>{userStats.totalPomodoros}</span>
                        <span className={styles.statLabel}>Total Pomodoros</span>
                    </div>
                </div>
                <div className={styles.statItem}>
                    <span className={styles.statIcon}>🏆</span>
                    <div className={styles.statContent}>
                        <span className={styles.statValue}>{userStats.rank}</span>
                        <span className={styles.statLabel}>Rank</span>
                    </div>
                </div>
            </div>

            <div className={styles.onlineUsers}>
                <div>Total online users: {onlineUsers.length}</div>
                <div className={styles.userList}>
                    {filteredUsers.map(user => (
                        <div key={user.id} className={styles.user}>
                            <img src={user.photo_url  ||'/path/to/default/avatar.jpg'} alt={`${user.name || 'Unknown'}'s avatar`} className={styles.userAvatar} />
                            <span className={styles.userName}>{user.name || 'Unknown'}</span>
                            {user.last_active && (
                                <div className={styles.userLastActive}>
                                    Last active: {new Date(user.last_active).toLocaleTimeString()}
                                </div>
                            )}
                            <div>Category: {user.category || 'N/A'}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.pomodoroCategories}>
                <div className={styles.categoryList}>
                    <div className={styles.categoryBox} onClick={() => handleCategorySelect('academic cluster')} data-tooltip="See colleagues working in this cluster">
                        <span>Academic Cluster</span>
                    </div>
                    <div className={styles.categoryBox} onClick={() => handleCategorySelect('professional cluster')} data-tooltip="See colleagues working in this cluster">
                        <span>Professional Cluster</span>
                    </div>
                    <div className={styles.categoryBox} onClick={() => handleCategorySelect('interest cluster')} data-tooltip="See colleagues working in this cluster">
                        <span>Interest Cluster</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

FocusBox.propTypes = {
    onClose: PropTypes.func.isRequired,
    isPomodoroRunning: PropTypes.bool.isRequired,
    timeLeft: PropTypes.number.isRequired,
    setIsPomodoroRunning: PropTypes.func.isRequired,
    setTimeLeft: PropTypes.func.isRequired,
    currentSession: PropTypes.string.isRequired,
    setCurrentSession: PropTypes.func.isRequired,
    completedPomodoros: PropTypes.number.isRequired,
    setCompletedPomodoros: PropTypes.func.isRequired,
};

export default FocusBox;
